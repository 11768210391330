import { jsx as _jsx } from "react/jsx-runtime";
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../config-global';
// components
import { useSettingsContext } from '../../components/settings';
// ----------------------------------------------------------------------
const SPACING = 8;
export default function Main({ children, sx, ...other }) {
    const { themeLayout } = useSettingsContext();
    const isNavHorizontal = themeLayout === 'horizontal';
    const isNavMini = themeLayout === 'mini';
    const isDesktop = useResponsive('up', 'lg');
    if (isNavHorizontal) {
        return (_jsx(Box, { component: "main", sx: {
                pt: `${HEADER.H_MOBILE + SPACING}px`,
                // pb: `${HEADER.H_MOBILE + SPACING}px`,
                ...(isDesktop && {
                    px: 2,
                    pt: `${HEADER.H_DASHBOARD_DESKTOP + 80}px`,
                    pb: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
                }),
                pb: 0,
            }, children: children }));
    }
    return (_jsx(Box, { component: "main", sx: {
            flexGrow: 1,
            py: `${HEADER.H_MOBILE + SPACING}px`,
            ...(isDesktop && {
                px: 2,
                py: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
                width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
                ...(isNavMini && {
                    width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
                }),
            }),
            ...sx,
        }, ...other, children: children }));
}
